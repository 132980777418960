import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container, MobileNav } from "./Components/index"
import { FaBars } from "react-icons/fa";
import { Home, Projects, Services, Service, Contact,Catalogues, NotFound } from "./Pages/index"
import "./App.css";
import { useRef,useEffect} from "react";
import { useTranslation } from "react-i18next";


function App() {
  const {i18n } = useTranslation();
  useEffect(()=>{
    document.documentElement.lang = i18n.language
  },[i18n,i18n.languages])
  document.body.className = i18n.language === 'ar' ? 'ar': 'lang';
  const openNav = (e) => {
    document.querySelector(".MNav").classList.add("isOpen")
    document.querySelector(".MNav").classList.remove("isClose")
  }
  const onload = useRef()
  const Links = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/projects",
      element: <Projects />
    },
    {
      path: "/services",
      element: <Services />
    }
    ,
    {
      path: "/services/:id",
      element: <Service />
    }
    ,
    {
      path: "/contact",
      element: <Contact />
    }
    ,
    {
      path: "/*",
      element: <NotFound />
    },
    {
      path:"/catalogues",
      element: <Catalogues />
    }
  ]
  const getLinks = Links.map((link,i)=>{
    return <Route key={i} path={link.path} element={link.element} />
  })



  return (
    <div onLoad={() => { onload.current.classList.add("loaded") }}>
      <div ref={onload} className="onload_c">
        <div className="onload"></div>
      </div>
      <Router basename="/">
        <div onClick={openNav} className="navOpen">
          <FaBars />
        </div>
        <MobileNav />
        <Container>
          <Routes>
            {getLinks}
          </Routes>
        </Container>

      </Router>
    </div>
  )
}

export default App
import React from 'react'
import { Link } from 'react-router-dom'

function CataloguesItem(props) {
  return (
    <div className="cataloguesItem_c">
      <Link target='_blank' to={props.catalogue} className="cataloguesItem">
        <div className='catalogueImg_c'>
          <img className="catalogueImg" src={props.img} alt="" />
        </div>
        <div className="catalogueContent">
          <p className="catalogueTitle">{props.title}</p>
        </div>
      </Link>
    </div>
  )
}

export default CataloguesItem
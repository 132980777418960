import "./AsideBoxs.css"
import { AsideBox} from "../../Components"
function AsideBoxs() {
    return (
        <div className="servicesAside">
            <AsideBox src="serviceLinks" />
        </div>
    )
}

export default AsideBoxs
import "./MainCategoryItem.css"

const MainCategoryItem = (props) => {
  return (
    <div className="MCI_c">
      <div className="mainCategoryItem">
        <a href={props.link} className="MCI_overlay">
          <p className="MC_overlaySub">{props.about}</p>
          <p className="MC_overlayText">{props.title}</p>
        </a>
        <img src={props.img} alt="" />
      </div>
    </div>
  )
}

export default MainCategoryItem
import './AboutUs.css'
import AboutImg_1 from "../../assets/images/about_1.png" 
import AboutImg_2 from "../../assets/images/about_2.png" 
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  const {t} = useTranslation()
  return (
    <>
      <div className='container aboutUs-c'>
        <div className='aboutUsImgs'>
          <img className='aboutUsImg aboutUsImg_1' src={AboutImg_1} alt="" />
          <img className='aboutUsImg aboutUsImg_2' src={AboutImg_2} alt="" />
        </div>
        <div className='aboutUsTexts'>
          <p className='aboutSub'>{t("aboutSub")}</p>
          <p className='aboutTitle'>{t("aboutTitle",{returnObjects:true}).line_1}
          <br/>{t("aboutTitle",{returnObjects:true}).line_2}</p>
          <p className='aboutCoute'>{t("aboutCoute")}</p>
          <p className='aboutText'>{t("aboutText")}</p>
          <p className='aboutText'>{t("aboutText_2")}</p>
        </div>
      </div>
    </>
  )
}

export default AboutUs
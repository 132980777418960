
const ServicesData = [

    {img:require("../assets/images/services/curtain/ADELİNE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ALLOS.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ALVAR.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ALZARA.jpg"),subCategory:"curtains"},

    {img:require("../assets/images/services/wallpanels/2.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/3.png"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/4.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/5.jpg"),subCategory:"wallpanels"},

    {img:require("../assets/images/services/wallpaper/2017.png"),subCategory:"wallpapers"},
    {img:require("../assets/images/services/wallpaper/2018.png"),subCategory:"wallpapers"},
    {img:require("../assets/images/services/wallpaper/green_1.png"),subCategory:"wallpapers"},
    {img:require("../assets/images/services/wallpaper/pink_1.png"),subCategory:"wallpapers"},
    {img:require("../assets/images/services/wallpaper/pink_2.png"),subCategory:"wallpapers"},
    {img:require("../assets/images/services/wallpaper/pink_3.png"),subCategory:"wallpapers"},  

    {img:require("../assets/images/services/curtain/CERVANTES.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/DALI.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/perde.png"),subCategory:"curtains"},
    /*----------------------tulle*/
    {img:require("../assets/images/services/curtain/APEX-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ARAL-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ARUN-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/BOHR-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/DECO-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/LOME-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/AGATA-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/ALICE-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/FROME-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/GARRY-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/GAUSS-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/BOLERO-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/BORNEO-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/DENTON-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/FLORIS-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/LUSAKA-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/MADIGAN-TULLE.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/DESCARTES-TULLE.jpg"),subCategory:"curtains"},




    {img:require("../assets/images/services/curtain/ERİKSON.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/FARADAY.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/Frida.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/GİBBS.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/HELBERT-PANO.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/HENLEY.jpg"),subCategory:"curtains"},
    {img:require("../assets/images/services/curtain/HOBBES.jpg"),subCategory:"curtains"},
    
    {img:require("../assets/images/services/wallpanels/11.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/12.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/13.jpg"),subCategory:"wallpanels"},

    {img:require("../assets/images/services/wallpanels/1.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/6.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/7.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/8.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/9.jpg"),subCategory:"wallpanels"},
    {img:require("../assets/images/services/wallpanels/10.jpg"),subCategory:"wallpanels"},

    {img:require("../assets/images/services/wallpaper/vera_1.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_2.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_3.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_4.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_5.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_6.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_7.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_8.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_9.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_10.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_11.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_12.jpg"),subCategory:"wallpapers"},    
    {img:require("../assets/images/services/wallpaper/vera_13.jpg"),subCategory:"wallpapers"},    
    


]

export default ServicesData
import React from 'react'
import ServicesLinksData from '../../Data/ServicesLinksData'
import { useTranslation } from 'react-i18next'

const AsideBox = () => {
    const t = useTranslation().t
    const ServicesLinkNames =[t("MC_overlayText").MC_1,t("MC_overlayText").MC_2,t("MC_overlayText").MC_3] 
    const getServicesLinks = ServicesLinksData.map((servicesLink,i)=>{
        return <a key={servicesLink.id} className='AsideBoxItem' href={servicesLink.link}>{ServicesLinkNames[i]}</a>
    })
    return (
        <div className="AsideBox_c">
            <p className="AsideBoxTitle">{t("otherServices")}</p>
            <hr width="100%" />
            <div className="AsideBoxItems">
                {getServicesLinks}
            </div>
        </div>
    )
}

export default AsideBox
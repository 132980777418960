import img_1 from "../assets/images/services/wallpaper/2017.png"
import img_2 from "../assets/images/services/curtain/perde.png"
import img_3 from "../assets/images/services/wallpanels/8.jpg"
const ServicesLinksData = [
    {
        id: 1,
        serviceName: "Curtain Models",
        about: "",
        link: "/services/curtains",
        img: img_2
    },
    {
        id: 2,
        serviceName: "Wallpaper Models",
        about: "",
        link: "/services/wallpapers",
        img: img_1
    },
    {
        id: 3,
        serviceName: "Wall Panels",
        about: "",
        link: "/services/wallpanels",
        img: img_3
    },
]


export default ServicesLinksData
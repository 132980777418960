import React from 'react'
import "./MobileNav.css"
import { FaTimes } from "react-icons/fa";
import img_1 from "../../assets/images/services/wallpaper/2017.png"
import img_2 from "../../assets/images/services/curtain/perde.png"
import img_3 from "../../assets/images/services/wallpanels/8.jpg"
import logo from "../../assets/images/horizon_logo.png"
import { useTranslation } from "react-i18next"

export default function MobileNav() {
  const {t,i18n} = useTranslation()
  function setLanguage(language) {
    localStorage.setItem('selectedLanguage', language);
  }
  const changeLang = (lang) => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }
  const ServicesLinksData = [
    {
        id:1, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_1,
        about:"",
        link:"/services/curtains",
        img:img_2
    },
    {
        id:2, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_2,
        about:"",
        link:"/services/wallpapers",
        img:img_1
    },
    {
        id:3, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_3,
        about:"",
        link:"/services/wallpanels",
        img:img_3
    },
  ]
  const languages = [{code:"en",language:"English"},{code:"tr",language:"Türkçe"},{code:"ar",language:"العربية"}]
  const closeNav = (e) => {
    document.querySelector(".MNav").classList.add("isClose")
    document.querySelector(".MNav").classList.remove("isOpen")
  }
  const getServicesLinksData = ServicesLinksData.map(ServicesLinkData => {
    return <a className='subLink' key={ServicesLinkData.id} href={ServicesLinkData.link}>{ServicesLinkData.serviceName}</a>
  })
  return (
    <div id="MNavbar" className="MNav">
      <div onClick={closeNav} className='navClose'>
        <FaTimes />
      </div>
      <a href="/" className="MNavbar-brand">
        <img src={logo} alt="" />
      </a>
      <div className="MNav-list-c">
        <ul className="MNav-list flex-v">
          <li className="MNav-item"><a href="/" className="nav-link underline_link">{t("navLink_home")}</a></li>
          <li className="MNav-item"><span href="/" className="nav-link">{t("navLink_services")}</span>
            <div className='subLinks'>
            <a className='subLink' href="/services">{t("navLink_servicesAll")}</a>
              {getServicesLinksData}
            </div>
          </li>
          <li className="MNav-item"><a href="/projects" className="nav-link underline_link">{t("navLink_projects")}</a></li>
          <li className="MNav-item"><span href="/" className="nav-link">{t("languages")}</span>
            <div className='subLinks'>
            {
                languages.map((lang,i)=>{
                  return <span key={i} className={lang.code === i18n.language?"subLink navSelected":"subLink"} onClick={()=>{changeLang(lang.code)}}>{lang.language}</span>
                })
              }
            </div>
          </li>
          <li className="MNav-item"><a href="/catalogues" className="nav-link underline_link">{t("catalogues")}</a></li>
          <li className="MNav-item"><a href="/contact" className="nav-link underline_link">{t("contactUs")}</a></li>
        </ul>
      </div>
    </div>
  )
}

import './Contact.css'
import Iframe from 'react-iframe'
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Nav, Footer, SectionHero } from '../../Components'
import { useTranslation } from 'react-i18next';

function Contact() {
  const t = useTranslation().t
  return (
    <div>
      <Nav />
      <SectionHero we=""/>
      <div className='container contact_c'>
        <div className="contactAsideBox_c AsideBox_c">
          <p className="AsideBoxTitle">{t("contactUs")}</p>
          <hr width="100%" />
          <div className="AsideBoxItems">
            <div className="AsideBoxItem AsideTextsBoxItem">
              <FaPhoneAlt size={25} />
              <div className="AsideTextsItem_c">
                <p className="AsideTextsTitle">{t("phoneNumber")}</p>
                <a href="tel:+90 537 738 73 99" className="AsideTextsText">+90 537 738 73 99</a>
              </div>
            </div>
            <div className="AsideBoxItem AsideTextsBoxItem">
              <FaEnvelope size={25} />
              <div className="AsideTextsItem_c">
                <p className="AsideTextsTitle">{t("email")}</p>
                <p className="AsideTextsText">info@horizons-d.com</p>

              </div>
            </div>
            <div className="AsideBoxItem AsideTextsBoxItem">
              <FaMapMarkerAlt size={25} />
              <div className="AsideTextsItem_c">
                <p className="AsideTextsTitle">{t("location")}</p>
                <p className="AsideTextsText">Başakşehir / İstanbul</p>

              </div>
            </div>
          </div>
        </div>
        <div className='gMap'>
          <Iframe width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4772.523662086388!2d28.788481622101674!3d41.1017365753411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caaff915c5cd13%3A0x7b4913d169217bbf!2zSG9yaXpvbiBQZXJkZSB2ZSBEdXZhciBLYcSfxLFkxLE!5e0!3m2!1str!2str!4v1712016001378!5m2!1str!2str" loading="lazy"/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
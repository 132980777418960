import "./Services.css"
import ServicesData from "../../Data/ServicesData";
import { SectionHero, AsideBoxs } from "../../Components"
import { Footer, Nav } from "../../Components/index";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";


const Services = () => {
  const {t} = useTranslation()
  const [file, setFile] = useState()
  const popUpImg = useRef();
  useEffect(() => {
    if (popUpImg.current) {
      if (popUpImg.current.naturalHeight > popUpImg.current.naturalWidth) {
        popUpImg.current.classList.add("aspect-v")
        popUpImg.current.classList.remove("aspect-h")
      }
      else {
        popUpImg.current.classList.remove("aspect-v")
        popUpImg.current.classList.add("aspect-h")
      }
    }
  }, [file])
  return (
    <>
      <Nav />
      <SectionHero>
        <h1 className='sectionHeroTitle'>
          {t("ourServices")}
        </h1>
      </SectionHero>
      <div className="container services_c">
        <AsideBoxs />
        <div className="images_c">
          {
            ServicesData.map(
              (serviceData, i) => {
                return <div key={i} className="imagesBox" onClick={() => { setFile(serviceData.img) }}>
                  <img src={serviceData.img} alt="" />
                </div>
              }
            )
          }
        </div>
        <div className="popup-media" style={{ display: file ? "block" : "none" }}>
          <div className="popupBg"></div>
          <span className="popup-close" onClick={() => { setFile(null) }}>&times;</span>
          <img src={file ? file : ""} alt="" />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Services


const ProjectsData = [
    { img: require('../assets/images/projects/IMG_12.JPEG') },
    { img: require('../assets/images/projects/IMG_13.JPEG') },
    { img: require('../assets/images/projects/IMG_14.JPEG') },
    { img: require('../assets/images/projects/IMG_26.jpg') },
    { img: require('../assets/images/projects/IMG_27.jpg') },
    { img: require('../assets/images/projects/IMG_1.JPEG') },
    { img: require('../assets/images/projects/IMG_2.JPEG') },
    { img: require('../assets/images/projects/IMG_17.jpg') },
    { img: require('../assets/images/projects/IMG_18.jpg') },
    { img: require('../assets/images/projects/IMG_19.jpg') },
    { img: require('../assets/images/projects/IMG_20.JPEG') },
    { img: require('../assets/images/projects/IMG_21.jpg') },
    { img: require('../assets/images/projects/IMG_15.jpg') },
    { img: require('../assets/images/projects/IMG_16.jpg') },
    { img: require('../assets/images/projects/IMG_22.jpg') },
    { img: require('../assets/images/projects/IMG_8101.webp') },
    { img: require('../assets/images/projects/IMG_8102.webp') },
    { img: require('../assets/images/projects/IMG_8104.webp') },
    { img: require('../assets/images/projects/IMG_8105.webp') },
    { img: require('../assets/images/projects/IMG_8108.webp') },
    { img: require('../assets/images/projects/IMG_8103.webp') },
    { img: require('../assets/images/projects/IMG_8106.webp') },
    { img: require('../assets/images/projects/IMG_8107.webp') },
    { img: require('../assets/images/projects/IMG_8109.webp') },
    { img: require('../assets/images/projects/IMG_8111.webp') },
    { img: require('../assets/images/projects/IMG_23.jpg') },
    { img: require('../assets/images/projects/IMG_24.JPEG') },
    { img: require('../assets/images/projects/IMG_25.jpg') },
    { img: require('../assets/images/projects/IMG_28.JPEG') },
    { img: require('../assets/images/projects/IMG_29.JPEG') },
    { img: require('../assets/images/projects/IMG_30.JPEG') },
    { img: require('../assets/images/projects/IMG_31.JPEG') },
    { img: require('../assets/images/projects/IMG_32.JPEG') },
    { img: require('../assets/images/projects/IMG_5.jpg') },
    { img: require('../assets/images/projects/IMG_6.JPEG') },
    { img: require('../assets/images/projects/IMG_7.png') },
    { img: require('../assets/images/projects/IMG_8.jpg') },
    { img: require('../assets/images/projects/IMG_9.jpg') },
    { img: require('../assets/images/projects/IMG_3.JPEG') },
    { img: require('../assets/images/projects/IMG_4.JPEG') },
    { img: require('../assets/images/projects/IMG_10.jpg') },
    { img: require('../assets/images/projects/IMG_33.JPEG') },
    { img: require('../assets/images/projects/IMG_34.jpg') },
    { img: require('../assets/images/projects/IMG_35.jpg') },
    { img: require('../assets/images/projects/IMG_36.jpg') },
    { img: require('../assets/images/projects/IMG_37.JPG') },
    { img: require('../assets/images/projects/IMG_38.JPG') },
    { img: require('../assets/images/projects/IMG_39.JPG') },
    { img: require('../assets/images/projects/IMG_40.JPEG') },
    { img: require('../assets/images/projects/IMG_41.JPEG') },
    { img: require('../assets/images/projects/IMG_42.JPEG') },
    { img: require('../assets/images/projects/IMG_43.JPEG') },
    { img: require('../assets/images/projects/IMG_44.JPEG') },
    { img: require('../assets/images/projects/IMG_45.JPEG') },
    { img: require('../assets/images/projects/IMG_46.JPEG') },
    { img: require('../assets/images/projects/IMG_47.JPEG') },
    { img: require('../assets/images/projects/IMG_48.JPEG') },
    { img: require('../assets/images/projects/IMG_49.jpg') },
    { img: require('../assets/images/projects/IMG_50.JPEG') },
    { img: require('../assets/images/projects/IMG_51.JPEG') },
    { img: require('../assets/images/projects/IMG_52.jpg') },
    { img: require('../assets/images/projects/IMG_53.JPEG') },
    { img: require('../assets/images/projects/IMG_54.JPEG') },
    { img: require('../assets/images/projects/IMG_55.JPEG') },
    { img: require('../assets/images/projects/IMG_56.JPEG') },
    { img: require('../assets/images/projects/IMG_57.JPEG') },
    { img: require('../assets/images/projects/IMG_58.jpg') },
    { img: require('../assets/images/projects/IMG_59.jpg') },
    { img: require('../assets/images/projects/IMG_60.JPEG') },
    { img: require('../assets/images/projects/IMG_61.JPEG') },
    { img: require('../assets/images/projects/IMG_62.jpg') },
    { img: require('../assets/images/projects/IMG_63.jpg') },
    { img: require('../assets/images/projects/IMG_64.jpg') },
    { img: require('../assets/images/projects/IMG_65.jpg') },
    { img: require('../assets/images/projects/IMG_66.jpg') },
    { img: require('../assets/images/projects/IMG_67.jpg') },
    { img: require('../assets/images/projects/IMG_68.jpg') },
    { img: require('../assets/images/projects/IMG_69.jpg') },
    { img: require('../assets/images/projects/IMG_70.jpg') },
    { img: require('../assets/images/projects/IMG_71.jpg') },
    { img: require('../assets/images/projects/IMG_72.jpg') },
    { img: require('../assets/images/projects/IMG_73.jpg') },
    { img: require('../assets/images/projects/IMG_74.jpg') },
    { img: require('../assets/images/projects/IMG_75.jpg') },
    { img: require('../assets/images/projects/IMG_76.jpg') },
    { img: require('../assets/images/projects/IMG_77.jpg') },
    { img: require('../assets/images/projects/IMG_78.jpg') },
    { img: require('../assets/images/projects/IMG_79.jpg') },
    { img: require('../assets/images/projects/IMG_80.jpg') },
    { img: require('../assets/images/projects/IMG_81.jpg') },
    { img: require('../assets/images/projects/IMG_82.jpg') },
    { img: require('../assets/images/projects/IMG_83.jpg') },
    { img: require('../assets/images/projects/IMG_84.jpg') },
    { img: require('../assets/images/projects/IMG_85.jpg') },
    { img: require('../assets/images/projects/IMG_86.jpg') },
    { img: require('../assets/images/projects/IMG_87.jpg') },
    { img: require('../assets/images/projects/IMG_88.jpg') },
    { img: require('../assets/images/projects/IMG_89.jpg') },
    { img: require('../assets/images/projects/IMG_90.jpg') },
    { img: require('../assets/images/projects/IMG_91.jpg') },
    { img: require('../assets/images/projects/IMG_92.jpg') },
    { img: require('../assets/images/projects/IMG_93.jpg') },
    { img: require('../assets/images/projects/IMG_94.jpg') },
    { img: require('../assets/images/projects/IMG_95.jpg') },
    { img: require('../assets/images/projects/IMG_96.jpg') },
    { img: require('../assets/images/projects/IMG_97.jpg') },
    { img: require('../assets/images/projects/IMG_98.jpg') },
    { img: require('../assets/images/projects/IMG_99.jpg') },
    { img: require('../assets/images/projects/IMG_100.jpg') },
    { img: require('../assets/images/projects/IMG_101.jpg') },
    { img: require('../assets/images/projects/IMG_102.jpg') },
    { img: require('../assets/images/projects/IMG_103.jpg') },
    { img: require('../assets/images/projects/IMG_104.jpg') },
    { img: require('../assets/images/projects/IMG_105.jpg') },
    { img: require('../assets/images/projects/IMG_106.jpg') },
]

export default ProjectsData
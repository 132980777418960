const CataloguesData = [    
    {
        img:require("../assets/catalogues/images/adakids-kapak.png"),
        title:"ADAKİDS",
        catalogue:require("../assets/catalogues/pdf/adakids-catalogue.pdf")
    },    
    {
        img:require("../assets/catalogues/images/alfa-kapak.png"),
        title:"ALFA",
        catalogue:require("../assets/catalogues/pdf/alfa-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/hit-4-kapak.jpg"),
        title:"HİT-4",
        catalogue:require("../assets/catalogues/pdf/hit-4-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/hit-3-kapak.jpg"),
        title:"HİT-3",
        catalogue:require("../assets/catalogues/pdf/hit-3-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/anka-kapak.png"),
        title:"ANKA",
        catalogue:require("../assets/catalogues/pdf/anka-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/havana-kapak.png"),
        title:"HAVANA",
        catalogue:require("../assets/catalogues/pdf/havana-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/beta-kapak.png"),
        title:"BETA",
        catalogue:require("../assets/catalogues/pdf/beta-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/dante-kapak.png"),
        title:"DANTE",
        catalogue:require("../assets/catalogues/pdf/dante-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/indigo-kapak.png"),
        title:"INDIGO",
        catalogue:require("../assets/catalogues/pdf/indigo-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/kalinka-kapak.png"),
        title:"KALİNKA",
        catalogue:require("../assets/catalogues/pdf/kalinka-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/octagon-kapak.png"),
        title:"OCTAGON",
        catalogue:require("../assets/catalogues/pdf/octagon-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/roka-kapak.jpg"),
        title:"ROKA",
        catalogue:require("../assets/catalogues/pdf/roka-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/rumi-kapak.png"),
        title:"RUMİ",
        catalogue:require("../assets/catalogues/pdf/rumi-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/seyyah-kapak.png"),
        title:"SEYYAH",
        catalogue:require("../assets/catalogues/pdf/seyyah-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/signature-kapak.png"),
        title:"SİGNATURE",
        catalogue:require("../assets/catalogues/pdf/signature-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/tropicano-kapak.png"),
        title:"TROPİCANO",
        catalogue:require("../assets/catalogues/pdf/tropicano-catalogue.pdf")
    },
    {
        img:require("../assets/catalogues/images/vera-kapak.png"),
        title:"VERA",
        catalogue:require("../assets/catalogues/pdf/vera-catalogue.pdf")
    },

]
export default CataloguesData
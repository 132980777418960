import "./Container.css"

const Container = (props) => {
  return (
    <>
      {props.children}
    </>
  )
}

export default Container
import "./Service.css"
import ServicesData from "../../Data/ServicesData";
import { SectionHero,AsideBoxs } from "../../Components"
import { Footer, Nav } from "../../Components/index"
import { Navigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Service = () => {
  const t = useTranslation().t
  const params = useParams()
  const paramsId = params.id
  const [file, setFile] = useState()

  const getServices = () => {
    return ServicesData.filter((el) => {
        return el.subCategory === paramsId
      }).map(
        (serviceData,i) => {
          return <div key={i} className="imagesBox" onClick={() => { setFile(serviceData.img) }}>
          <img  src={serviceData.img} alt="" />
        </div>
        }
      )
  }
  return (
    <>
      <Nav />
      <SectionHero>
        <h1 className='sectionHeroTitle'>
          {t("ourServices")}
        </h1>
      </SectionHero>
      <div className="container services_c">
        <AsideBoxs />
        <div className="images_c">
          {getServices().length?getServices():<Navigate to={"/NotFound"}/>}
        </div>
        <div className="popup-media" style={{display: file ? "block" : "none"}}>
          <div className="popupBg"></div>
          <span className="popup-close" onClick={()=>{setFile(null)}}>&times;</span>
          <img src={file?file:""} alt="" />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Service
import "./MainCategories.css"
import img_1 from "../../assets/images/services/wallpaper/2017.png"
import img_2 from "../../assets/images/services/curtain/perde.png"
import img_3 from "../../assets/images/services/wallpanels/8.jpg"
import MainCategoryItem from "../../Components/MainCategoryItem/MainCategoryItem"
import { useTranslation } from "react-i18next"
const MainCategories = () => {
  const {t} = useTranslation()
  const ServicesLinksData = [
    {
        id:1, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_1,
        about:"",
        link:"/services/curtains",
        img:img_2
    },
    {
        id:2, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_2,
        about:"",
        link:"/services/wallpapers",
        img:img_1
    },
    {
        id:3, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_3,
        about:"",
        link:"/services/wallpanels",
        img:img_3
    },
]
  const getServicesLinksData = ServicesLinksData.map(serviceLink=>{
    return <MainCategoryItem about="" key={serviceLink.id} title={serviceLink.serviceName} img={serviceLink.img} link={serviceLink.link}/>
  })
  return (
    <div className="container mainCategories_c">
      {
        getServicesLinksData
      }
    </div>
  )
}

export default MainCategories
import "./Nav.css"
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/images/horizon_logo.svg"
import img_1 from "../../assets/images/services/wallpaper/2017.png"
import img_2 from "../../assets/images/services/curtain/perde.png"
import img_3 from "../../assets/images/services/wallpanels/8.jpg"
import { useRef } from "react";
import { useTranslation } from "react-i18next";


const Nav = () => {
  const { t, i18n } = useTranslation();
  const ServicesLinksData = [
    {
        id:1, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_1,
        about:"",
        link:"/services/curtains",
        img:img_2
    },
    {
        id:2, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_2,
        about:"",
        link:"/services/wallpapers",
        img:img_1
    },
    {
        id:3, 
        serviceName:t("MC_overlayText",{returnObjects:true}).MC_3,
        about:"",
        link:"/services/wallpanels",
        img:img_3
    },
  ]
  function setLanguage(language) {
    localStorage.setItem('selectedLanguage', language);
  }
  const languages = [{code:"en",language:"English"},{code:"tr",language:"Türkçe"},{code:"ar",language:"العربية"}]
  const navbar = useRef()
  window.onscroll = function () {
    if (window.scrollY >= 10) {
      navbar.current.classList.add("nav-colored");
      navbar.current.classList.remove("nav-transparent");
    }
    else {
      navbar.current.classList.add("nav-transparent");
      navbar.current.classList.remove("nav-colored");
    }
  };
  const changeLang = (lang) => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }
  // useEffect(()=>{
  //   document.body.dir = i18n.dir()
  // },[i18n,i18n.languages])
  const getServicesLinksData = ServicesLinksData.map(ServicesLinkData => {
    return <Dropdown.Item key={ServicesLinkData.id} href={ServicesLinkData.link}>{ServicesLinkData.serviceName}</Dropdown.Item>
  })

  return (
    <div id="Navbar" ref={navbar} className="navbar navbar-expand-md flex-h">
      <a href="/" className="navbar-brand">
        <img src={logo} alt="" />
      </a>
      <div className="nav-list-c">
        <ul className="nav-list flex-h">
          <li className="nav-item"><a href="/" className="nav-link underline_link">{t("navLink_home")}</a></li>
          <Dropdown variant="transparent" className="nav-item" id="dropdown-custom-components">
            <Dropdown.Toggle className="nav-link underline_link">
              {t("navLink_services")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/services">{t("navLink_servicesAll")}</Dropdown.Item>
              {getServicesLinksData}
            </Dropdown.Menu>
          </Dropdown>
          <li className="nav-item"><a href="/projects" className="nav-link underline_link">{t("navLink_projects")}</a></li>
          <Dropdown variant="transparent" className="nav-item" id="dropdown-custom-components">
            <Dropdown.Toggle className="nav-link underline_link">
              {t("navLink_languages")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                languages.map((lang,i)=>{
                  return <Dropdown.Item key={i} className={lang.code === i18n.language?"navSelected":""} onClick={()=>{changeLang(lang.code)}}>{lang.language}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
          <li className="nav-item"><a href="/catalogues" className="nav-link underline_link">{t("catalogues")}</a></li>
          <li className="nav-item"><a href="/contact" className="nav-link underline_link">{t("contactUs")}</a></li>
        </ul>
      </div>
    </div>


  )

}

export default Nav
import "./Companies.css"
import CompaniesData from "../../Data/CompaniesData"

const Reviews = () => {
  const getCompaniesImgs = CompaniesData.map((CompanyImg) => {
    return <img className="CompanyImg" key={CompanyImg.id} src={CompanyImg.img} alt="" />
  })
  return (
    <div className="container companies_c">
      {
        getCompaniesImgs
      }
    </div>
  )
}

export default Reviews
import "./BeforeAfter.css"
import afterImg from "../../assets/images/after.png"
import beforeImg from "../../assets/images/before.png"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { useRef } from "react"

const BeforeAfter = () => {
  const {t} = useTranslation()
  const after_image = useRef()
  const afterRange = useRef()
  const beforeLabel = useRef()
  const afterLabel = useRef()
  const beforeInput = (e) => {
    afterLabel.current.style.left = afterRange.current.value - 1.2 + "%"
    beforeLabel.current.style.left = afterRange.current.value - 1.2 + "%"
    after_image.current.style.width = afterRange.current.value - 1.2 + "%"
  }

  return (
    <>
      <div className="beforeAfter">
        <div className='container beforeAfterTitle_c'>
          <p className='beforeAfterSub'>{t("beforeAfterSub")}</p>
          <p className='beforeAfterTitle'>{t("beforeAfterTitle",{returnObjects:true}).line_1} <br />{t("beforeAfterTitle",{returnObjects:true}).line_2} </p>
        </div>
        <div className="beforeAfterShape"></div>
        <div className="beforeAfterContent">
          <div className="beforeAfterShapeBg"></div>
          <img className="before" src={beforeImg} alt="" />
          <label ref={beforeLabel} className="beforeLabel" />
          <input id="beforeInput" ref={afterRange} onInput={beforeInput} type="range" min="2.5" max="100" step="1.2" />
          <label className="afterLabel" ref={afterLabel}>
            <FaArrowLeft />
            <FaArrowRight />
          </label>
          <div className="after_c">
            <img ref={after_image} className="after" src={afterImg} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default BeforeAfter
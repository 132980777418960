import { Hero, AboutUs, MainCategories, BeforeAfter, Companies } from "../../Sections/index"
import { Footer, Nav } from "../../Components/index"

const Home = () => {
    return (
        <>
            <Nav />
            <Hero />
            <AboutUs />
            <MainCategories />
            <BeforeAfter />
            <Companies />
            <Footer />
        </>
    )
}

export default Home
import "./SectionHero.css"
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const SectionHero = (props) => {
  const pathNameSplit = window.location.pathname.split("/")

  return (
    <div className='sectionHero_c'>
      {props.children}
      <div className='sectionHeroLinks flex-h'>
        <FaHome /> <Link to={"/"}>Home</Link>
        <span>/</span>
        <Link to={"/"+pathNameSplit[1]}>{pathNameSplit[1]}</Link>
        {pathNameSplit[2]&&<span>/</span>}
        <Link to={`${window.location.pathname}`}>{pathNameSplit[2]}</Link>
      </div>
    </div>
  )
}

export default SectionHero
import img_1 from "../assets/images/companies/adawall.png"
import img_2 from "../assets/images/companies/drn.png"
import img_3 from "../assets/images/companies/duka.png"
import img_4 from "../assets/images/companies/ercan.png"
import img_5 from "../assets/images/companies/mucho.png"
import img_6 from "../assets/images/companies/vertu.png"

const CompaniesData = [
    
    {id:1,img:img_1},
    {id:2,img:img_2},
    {id:3,img:img_3},
    {id:4,img:img_4},
    {id:5,img:img_5},
    {id:6,img:img_6},
]

export default CompaniesData

